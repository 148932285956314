import React from 'react'
import "./Aboutus.css"
import about from "../../Assests/about.jpg"
import g from "../../Assests/g.png"
import p from "../../Assests/p.png"
import {motion } from "framer-motion"
import "../../Components/Slider/Slider.css"
import o3 from "../../Assests/o5.png"
import o1 from "../../Assests/o7.png"
import o2 from "../../Assests/0.png"
const Aboutus = () => {
   const fadeInRight = {
      hidden: { opacity: 0, x: 100 },
      visible: { opacity: 1, x: 0, transition: { duration: 1, ease: "easeInOut" } },
    };
    const fadeInUp = {
      hidden: { opacity: 0, y: 50 },
      visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
    };
    const fadeInLeft = {
      hidden: { opacity: 0, x: -100 },
      visible: { opacity: 1, x: 0, transition: { duration: 1, ease: "easeInOut" } },
    };
   return (
      <>
         {/* banner */}
         <div className="banner-container">
            <div className="banner-overlay"></div> {/* Overlay */}
            <div className="banner-content" style={{top:"45%"}}>
               <br />
               <motion.h1
               initial="hidden"
               whileInView="visible"
               variants={fadeInLeft}
               viewport={{ once: true }}
                className="title">ABOUT US </motion.h1>
               {/* <motion.p
               initial="hidden"
               whileInView="visible"
               variants={fadeInLeft}
               viewport={{ once: true }}
                className="subtitle">ECS &gt; About</motion.p> */}
            </div>
            <img src={about} style={{height:"35em"}} alt="Banner" className="banner-image" />
         </div>
      
         <br />
         {/* <div class="about">
            <div class="container">
               <div class="row align-items-center">
                  <div class="col-lg-5 col-md-6">
                     <motion.div 
                               initial="hidden"
                               whileInView="visible"
                               variants={fadeInUp}
                               viewport={{ once: true }}
                     class="about-img">
                        <img src={about} alt="Image" />
                     </motion.div>
                  </div>
                  <div class="col-lg-7 col-md-6">
                     <motion.div 
                               initial="hidden"
                               whileInView="visible"
                               variants={fadeInUp}
                               viewport={{ once: true }}
                     class="section-header text-left">
                        <p>Learn About Us</p>
                        <h2>We never forget that we are working for you, not the suppliers
                        </h2>
                     </motion.div>
                     <motion.div 
                               initial="hidden"
                               whileInView="visible"
                               variants={fadeInUp}
                               viewport={{ once: true }}
                     class="about-text">
                        <p>
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec pretium mi. Curabitur facilisis ornare velit non vulputate. Aliquam metus tortor, auctor id gravida condimentum, viverra quis sem.
                        </p>
                        <p>
                           Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sed, et. Nemo, facilis quo temporibus sapiente, reiciendis reprehenderit atque dolores et cupiditate eaque voluptas aut! Debitis hic cum cupiditate laudantium molestiae?
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec pretium mi. Curabitur facilisis ornare velit non vulputate. Aliquam metus tortor, auctor id gravida condimentum, viverra quis sem. Curabitur non nisl nec nisi scelerisque maximus. Aenean consectetur convallis porttitor. Aliquam interdum at lacus non blandit.
                        </p>
                        <a class="btn" href="">Learn More</a>
                     </motion.div>
                  </div>
               </div>
            </div>
         </div> */}

         {/*  */}
       
         <div  class="services">
            <div class="container">
               <div class="row">
                  <div class="col-md-10 offset-md-1">
                     <div class="titlepage">
                        <h2>Our Services</h2>
                        <p>Finding you the right service, ECS works efficiently so you don't have to!</p>
                     </div>
                  </div>
               </div>
               <div class="row">
                  <div class="col-md-4">
                     <div id="serv_hover"  class="services_box">
                        <i><img  style={{width:"7em",height:"7em"}} src={o2} alt="#"/></i>
                        <h3>Electricity</h3>
                        <br />
                        <p>We negotiate with suppliers on your behalf to get the best electricity product for you.</p>
                        {/* <a class="right_irro" href="#"><i class="fa fa-long-arrow-right" aria-hidden="true"></i></a> */}
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div id="serv_hover" class="services_box">
                        <i><img style={{width:"6em",height:"6em"}} src={o1} alt="#"/></i>
                        <h3>Water</h3>
                        <br />
                        <p>We find the best product and gain competitive tarrif for your gas supply.</p>
                        {/* <a class="right_irro" href="#"><i class="fa fa-long-arrow-right" aria-hidden="true"></i></a> */}
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div id="serv_hover" class="services_box">
                        <i><img style={{width:"7em",height:"7em"}} src={o3} alt="#"/></i>
                        <h3>Gas</h3>
                        <br />
                        <p>We take the confusion out of the equation and help you find the best water contract on your behalf.</p>
                        {/* <a class="right_irro" href="#"><i class="fa fa-long-arrow-right" aria-hidden="true"></i></a> */}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

export default Aboutus
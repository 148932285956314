import './App.css';
import Footer from './Components/Footer/Footer';
import Home from './Components/Home/Home';
import Homee from './Components/Homee/Homee';
import Navbarr from './Components/Navbarr/Navbarr';
import Sidebar from './Components/Sidebar/Sidebar';
import Sider from './Components/Slider/Sider';
import Slider from './Components/Slider/Sider';
import Testimonials from './Components/Testimonial/Testimonial';
import Homepage from './Pages/Homepage/Homepage';
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Aboutus from './Pages/Aboutus/Aboutus';
import Whatsapp from './Components/Whatsapp/Whatsapp';
import Contactus from './Pages/Contactus/Contactus';

function App() {
  return (
   <>
   <Whatsapp/>
   <Navbarr/>
   
    <BrowserRouter>
          <Routes>
            <Route path="/" element={<Homepage/>} />
            <Route path="/aboutus" element={<Aboutus/>} />
            <Route path="/contactus" element={<Contactus/>} />
         

          </Routes>
        </BrowserRouter>
   
   <br/>
   <Footer/>
   </>
  );
}

export default App;
